export const initialState: {} = {
  app: {
    isAdmin: false,
    NODE_ENV: "",
    publish: {
      formMethod: "CREATE",
      scheduleToEdit: {},
      scheduleToDelete: {}
    },
    themeFiles: {},
    theme: {
      isEdit: false,
      allSchedules: [],
      groupName: "",
      groupDescription: "",
      scheduleToEdit: {},
      schedules: {},
      selectedSection: {},
      currentSection: {
        settings: {}
      },
      changedSections: {},
      changedBlocks: {},
      selectedSectionId: "",
      selectedBlockId: "",
      allThemes: [],
      themeFiles: {},
      selectedTemplateKey: "templates/index.json",
      selectedTheme: "",
      currentTheme: {},
      sectionsGroupKey: ""
    },
    loading: {
      sectionSettingsLoading: true,
      saving: false
    },
    allThemes: []
  },
  merchant: {
    shop: {},
    media: [],
    menus: [],
    upgradeModal: {
      action: "CreateContent",
      persist: false
    }
  }
};
